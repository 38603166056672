import React from 'react';
import StorageInterface from 'components/StorageInterface';
import { Add, Remove, Delete } from '@material-ui/icons';
import Connector from 'components/Connector';

import './style.css';

class Cable extends React.Component {
	state = {
		image1: null,
		image2: null
	}

	async componentWillMount(){
		const image1 = await StorageInterface.getConnectorImage(this.props.side1);
		const image2 = await StorageInterface.getConnectorImage(this.props.side2);
		this.setState({image1, image2});
	}

	render() {
		return (
			<div className="cableCard" ref="card">

				<Connector
					name={this.props.side1}
					imageSource={this.state.image1}
					search={this.props.search}
				/>

				<Connector
					name={this.props.side2}
					imageSource={this.state.image2}
					search={this.props.search}
				/>

				{
					this.props.quantity > 0 ?
						<Remove onClick={this.remove.bind(this)}/>
						: 
						<Delete className="delete" onClick={this.delete.bind(this)}/>
				}				
				
				<span>{this.props.quantity}</span>

				<Add onClick={this.add.bind(this)}/>
			</div>
		);
	}

	add(){
		this.props.onQuantityChange(this.props.id, this.props.quantity + 1);
	}

	remove(){
		this.props.onQuantityChange(this.props.id, this.props.quantity - 1);
	}

	delete(){
		this.props.onDelete(this.props.id);
	}
}

export default Cable;