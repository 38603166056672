import React from 'react';
import Highlighter from 'react-highlight-words';

import './style.css';

class Connector extends React.Component {
	render() {
		return (
			<div onClick={this.onClick.bind(this)} className="connector" style={{backgroundColor: this.props.color}}>

				<img src={this.props.imageSource} alt={`img of ${this.props.name}-connector`} />

				{
					this.props.search ? 
						<Highlighter
							searchWords={this.props.search.split(' ')}
							autoEscape={true}
							textToHighlight={this.props.name}
						/>
						:
						<span>{this.props.name}</span>
				}
				
			</div>

		);
	}

	onClick(){
		if(this.props.onClick)
			this.props.onClick(this.props.name, this.props.img);
	}
}

export default Connector;