class StorageInterface{

	static async fetchConnectors(){
		const listRes = await fetch('/assets/connectors/list.json');
		const connectors = await listRes.json();
		for(const connector of connectors){
			const imageRes = await fetch(`/assets/connectors/images/${connector.imageName}`);
			const imageRaw = await imageRes.arrayBuffer();
			const image = 'data:image/png;base64,' + this.arrayBufferToBase64(imageRaw);
			this.addConnector(connector.name, image);
		}
	}

	static getCables(){
		let cablesString = localStorage.getItem('cableData');
		let cables = [];
		if(cablesString)
			cables = JSON.parse(cablesString);
		this.cables = cables;
		return cables;
	}

	static addCable(cable){
		const cables = this.getCables();
		cables.push(cable);
		localStorage.setItem('cableData', JSON.stringify(cables));
	}

	static storeCables(cables){
		localStorage.setItem('cableData', JSON.stringify(cables));
	}

	static async getConnectors(){
		let connectors = [];
		const cache = await caches.open('connectors');
		for(const request of await cache.keys()){
			const parts = request.url.split('/');
			const name = parts[parts.length-1];
			const res = await cache.match(request);
			const image = await res.text();
			connectors.push({
				name,
				image
			});
		}
		return connectors;
	}

	static async addConnector(name, image){
		const cache = await caches.open('connectors');
		const res = await new Response(image);
		await cache.put(`/connectors/${name}`, res);
	}

	static async getConnectorImage(name){
		const cache = await caches.open('connectors');
		const res = await cache.match('/connectors/'+name);
		const image = await res.text();
		return image;
	}

	static arrayBufferToBase64(buffer) {
		var binary = '';
		var bytes = [].slice.call(new Uint8Array(buffer));
		bytes.forEach((b) => binary += String.fromCharCode(b));
		return window.btoa(binary);
	}
}

export default StorageInterface;