import React from 'react';

import KeyColor from './KeyColor';
import Square from 'components/Square';

import './style.css';

class ConnectorImageEditor extends React.Component {
	state = {
		keyColors: []
	}

	componentDidMount(){
		this.init();
		this.props.onInstruction('Click on the background');
	}

	render() {
		return (
			<div id="connectorImageEditor">
				
				<Square id="imageEditor">
					<canvas onClick={this.addKeyColor.bind(this)} ref={canvas => this.editCanvas = canvas}></canvas>
					<canvas hidden ref={canvas => this.imageCanvas = canvas} ></canvas>
				</Square>

				<div id="keyColors">
					{
						this.state.keyColors.map((keyColor, index) =>
							<KeyColor
								key={index}
								id={index}
								keyColor={keyColor}
								onRemove={this.removeKeyColor.bind(this)}
								onThresholdChange={this.changeThreshold.bind(this)}
							/>
						)
					}
				</div>

				<div id="finish">
					<button onClick={this.createImage.bind(this)}>Finished</button>
				</div>			
				
			</div>
		);
	}

	init(){
		const {width, height} = this.props.imageData;

		this.editCanvas.width = width;
		this.editCanvas.height = height;
		this.editCtx = this.editCanvas.getContext('2d');
		this.editCtx.putImageData(this.props.imageData, 0, 0);
		
		this.imageCanvas.width = width;
		this.imageCanvas.height = height;
		this.imageCtx = this.imageCanvas.getContext('2d');
		this.imageCtx.putImageData(this.props.imageData, 0, 0);
		

		this.imageWorker = new Worker('/assets/scripts/imageWorker.js');
		this.imageWorker.onmessage = this.onImageworkerMessage.bind(this);
	}

	componentDidUpdate(oldProps, oldState){
		// eslint-disable-next-line
		if(oldState.keyColors != this.state.keyColors){
			this.updateImage();
		}
	}

	updateImage(){
		let image = this.imageCtx.getImageData(0, 0, this.imageCanvas.width, this.imageCanvas.height);
		this.imageWorker.postMessage({
			type: 'edit',
			image: image,
			keyColors: this.state.keyColors
		});
	}

	addKeyColor(e){
		const {x, y} = this.getMousePosOnCanvas(e.clientX, e.clientY);
		let pixel = this.imageCtx.getImageData(x, y, 1, 1).data;
		let keyColor = {
			r: pixel[0],
			g: pixel[1],
			b: pixel[2],
			threshold: 10
		};
		this.setState(state =>{
			const keyColors = state.keyColors.concat(keyColor);
			return {keyColors: keyColors};
		});
	}
	
	removeKeyColor(index){
		this.setState(state =>{
			let keyColors = [...state.keyColors];
			keyColors.splice(index, 1);
			return {keyColors: keyColors};
		});
	}

	getMousePosOnCanvas(mouseX, mouseY){
		const rect = this.editCanvas.getBoundingClientRect();
		const scaleX = this.editCanvas.width / rect.width;
		const scaleY = this.editCanvas.height / rect.height;

		return {
			x: (mouseX - rect.left) * scaleX,
			y: (mouseY - rect.top) * scaleY
		};
	}

	changeThreshold(val, id){
		this.setState(state =>{
			let keyColors = [...state.keyColors];
			keyColors[id].threshold = val;
			return {keyColors};
		});
	}

	onImageworkerMessage(e){
		const {type} = e.data;
		if(type === 'edited'){
			const{image} = e.data;
			this.editCtx.putImageData(image, 0, 0);
		}
	}

	createImage(){
		const image = this.editCanvas.toDataURL();
		this.props.onImage(image);
	}
}

export default ConnectorImageEditor;