import React from 'react';

import './style.css';

class Square extends React.Component {
	state = {
		width: null,
		height: null
	}

	render() {
		return (
			<div
				ref={div => this.wrapper = div}
				id={this.props.id}
				className="square"
				style={{width: this.state.width, height: this.state.height}}
			>
				{this.props.children}
			</div>
		);
	}

	componentDidMount(){
		window.onresize = this.setDimensions.bind(this);
		this.setDimensions();
	}

	setDimensions(){
		this.setState({width: null, height: null});

		let {width, height} = this.wrapper.getBoundingClientRect();

		if(width <= height)
			height = width;
		else
			width = height;

		this.setState({width, height});
	}

	componentWillUnmount(){
		window.onresize = null;
	}
}

export default Square;