import React from 'react';
import ConnectorSelector from 'components/ConnectorSelector';
import AddCableProgress from 'components/AddCableProgress';
import CableList from 'components/CableList';
import StorageInterface from 'components/StorageInterface';

class AddCable extends React.Component {
	state = {
		progress: 1,
		side1: null,
		side2: null,
		quantity: 1
	}

	render() {
		return (
			<div>
				<AddCableProgress
					progress={this.state.progress}
				/>

				<div style={{width: '95%', margin: 'auto'}}>
					{
						this.state.progress <= 2 ? 
							<ConnectorSelector
								onSelect={this.handleSelect.bind(this)}
							/>
							:
							null
					}

					{
						this.state.progress === 3 ?
							<React.Fragment>
								<CableList
									search=""
									cables={[{
										side1: this.state.side1,
										side2: this.state.side2,
										quantity: this.state.quantity
									}]}
									onQuantityChange={this.handleQuantityChange.bind(this)}
									onDelete={this.handleDelete.bind(this)}
								/>
								<div style={{display: 'flex', justifyContent: 'center'}}>
									<button style={{margin: '20px'}} onClick={this.handleAddCable.bind(this)}>Add cable</button>
								</div>
							</React.Fragment>
							:
							null
					}
				</div>
				
			</div>
		);
	}

	handleSelect(name){
		if(this.state.progress === 1)
			this.setState({side1: name, progress: 2});
		else if(this.state.progress === 2)
			this.setState({side2: name, progress: 3, quantity: 1});
	}

	handleQuantityChange(id, quantity){
		this.setState({quantity});
	}

	handleDelete(){
		this.setState({progress: 1});
	}

	handleAddCable(){
		let cables = StorageInterface.getCables();
		const cable = {
			side1: this.state.side1,
			side2: this.state.side2,
			quantity: this.state.quantity
		};
		cables.push(cable);
		StorageInterface.storeCables(cables);
		this.props.history.push('/');
	}
}

export default AddCable;