import React from 'react';
import Connector from 'components/Connector';
import SearchBar from 'components/SearchBar';

import './style.css';
import ConnectorCreator from './ConnectorCreator';
import StorageInterface from 'components/StorageInterface';

class ConnectorSelector extends React.Component {
	state = {
		search: '',
		connectors: [],
		view: 'loading'
	}

	componentWillMount(){
		this.loadConnectors();
		this.colors = ['#9C27B0', '#2196F3', '#F44336', '#673AB7', '#FFEB3B', '#E91E63', '#FFC107', '#3F51B5', '#009688', '#CDDC39', '#00BCD4', '#4CAF50', '#03A9F4', '#FF9800'];
	}

	render() {
		let noDisplayedConnectors = true;

		return (
			<div style={style}>
				<div style={{display: 'flex', justifyContent: 'center', margin: '20px 0px'}}>
					<SearchBar
						onChange={this.search.bind(this)}
						search={this.state.search}
					/>
				</div>

				{(() => {
					switch(this.state.view){
						case 'loading':
							return <h2>loading</h2>;
						case 'select':
							return(
								<div id="connectors">
									{
										this.state.connectors.map((connector, index) => {
											let color = this.colors[index % this.colors.length];
											if(connector.name.toLowerCase().includes(this.state.search.toLowerCase())){
												noDisplayedConnectors = false;
												return(
													<Connector
														key={index}
														name={connector.name}
														imageSource={connector.image}
														color={color}
														onClick={this.props.onSelect}
														search={this.state.search}
													/>
												);
											}
											else{
												return null;
											}
										})				
									}
								</div>
							);
						case 'create':
							return (
								<div id="createConnectorView">
									<ConnectorCreator
										onCreate={this.createdConnector.bind(this)}
									/>
								</div>
								
							);
							
						default: 
							return null;
					}
				})()}

				{
					(noDisplayedConnectors && this.state.view === 'select') ? 
						<h2>Nothing found</h2>
						:
						null
				}

				<div id="createConnectorButton">
					<button onClick={this.createConnectorView.bind(this)}>add new connector</button>
				</div>

			</div>
		);
	}

	async loadConnectors(){
		let connectors = await StorageInterface.getConnectors();
		this.setState({connectors, view: 'select'});
	}

	search(val){
		this.setState({search: val});
	}

	createConnectorView(){
		this.setState({view: 'create'});
	}

	async createdConnector(name, image){
		this.setState({view: 'loading'});
		await StorageInterface.addConnector(name, image);
		this.loadConnectors();
	}
}

const style = {
	width: '100%',
	maxWidth: '700px',
	margin: 'auto'
};

export default ConnectorSelector;