import React from 'react';

import Connector from 'components/Connector';
import { Edit } from '@material-ui/icons';

import './style.css';

class ConnectorNameSetter extends React.Component {
	state = {
		name: ''
	}

	componentDidMount(){
		this.props.onInstruction('Choose a name');
	}

	render() {
		const validName = this.state.name.length > 0;
		return (
			<div style={{overflow: 'auto'}}>
				<div style={{width: '200px', margin: 'auto'}}>
					<Connector
						imageSource={this.props.image}
						name={validName ? this.state.name : 'new connector'}
					/>
				</div>

				<div id="nameInput">
					<div onClick={this.focus.bind(this)}>
						<Edit/>
					</div>

					<input
						value={this.state.name}
						placeholder="new connector"
						onChange={this.changeName.bind(this)}
						ref={input => this.nameInput = input}
					/>
									
					<button className={validName ? 'active' : 'null'} onClick={this.submitName.bind(this)}>Add</button>
				</div>
				
			</div>
		);
	}

	focus(){
		this.nameInput.focus();
	}

	changeName(e){
		let name = e.target.value;
		this.setState({name});
	}

	submitName(){
		if(this.state.name.length > 0)
			this.props.onName(this.state.name);
	}
}

export default ConnectorNameSetter;