import React from 'react';

import './style.css';

class ShutterButton extends React.Component {
	render() {
		return (
			<div onClick={this.props.onClick} id="shutterButton"></div>
		);
	}
}

export default ShutterButton;