import React from 'react';

import ConnectorImageMaker from './ConnectorImageMaker';
import ConnectorImageEditor from './ConnectorImageEditor';
import ConnectorNameSetter from './ConnectorNameSetter';

import './style.css';

class ConnectorCreator extends React.Component {
	state = {
		view: 'getImage',
		imageData: null,
		image: null,
		instruction: 'loading'
	}

	render() {
		return (
			<div id="connectorCreator">
				<h1>{this.state.instruction}</h1>

				{
					this.state.view === 'getImage' ?
						<ConnectorImageMaker
							onImageData={this.madeImage.bind(this)}
							onInstruction={this.setInstruction.bind(this)}
						/>
						:
						null
				}

				{
					this.state.view === 'editImage' ?
						<ConnectorImageEditor
							imageData={this.state.imageData}
							onImage={this.editedImage.bind(this)}
							onInstruction={this.setInstruction.bind(this)}
						/>
						:
						null
				}

				{
					this.state.view === 'setName' ? 
						<ConnectorNameSetter
							image={this.state.image}
							onName={this.setName.bind(this)}
							onInstruction={this.setInstruction.bind(this)}
						/>
						:
						null
				}
			</div>
		);
	}

	madeImage(imageData){
		this.setState({imageData, view: 'editImage'});
	}

	editedImage(image){
		this.setState({image, view: 'setName'});
	}

	setName(name){
		this.props.onCreate(name, this.state.image);
	}

	setInstruction(instruction){
		this.setState({instruction})
	}
}

export default ConnectorCreator; 