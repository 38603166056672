import React from 'react';
import { Close } from '@material-ui/icons';

import './style.css';

class KeyColor extends React.Component {
	render() {
		const {r, g, b, threshold} = this.props.keyColor;
		return (
			<div className="keyColor" style={{background: `rgb(${r}, ${g}, ${b})`}}>
				<Close onClick={this.remove.bind(this)} />
				<input type="range" min="0" max="130" step="5" value={threshold} onChange={this.changeThreshold.bind(this)}/>
			</div>
		);
	}

	remove(){
		this.props.onRemove(this.props.id);
	}

	changeThreshold(e){
		this.props.onThresholdChange(parseInt(e.target.value), this.props.id);
	}
}

export default KeyColor;