import React from 'react';
import { ReactComponent as CableImg } from 'assets/Cable.svg';

import './style.css';

class AddCableProgress extends React.Component {
	render() {
		let svgClass = '';
		if(this.props.progress === 2)
			svgClass = 'second';
		else if(this.props.progress === 3)
			svgClass = 'full';

		return (
			<div className="addCableProgress">
				<CableImg
					className={svgClass}
				/>
				{
					this.props.progress === 1 ?
						<h2>Choose the first connector</h2>
						:
						this.props.progress === 2 ?
							<h2>Choose the second connector</h2>
							:
							this.props.progress === 3 ?
								<h2>Is this correct?</h2>
								:
								null
				}
			</div>
		);
	}
}

export default AddCableProgress;