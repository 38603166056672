import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Home from 'views/Home';
import AddCable from 'views/AddCable';
import StorageInterface from 'components/StorageInterface';

import './App.css';

class App extends React.Component {

	componentDidMount(){
		StorageInterface.fetchConnectors();
	}

	render() {
		return (
			<div>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route path="/add" component={AddCable} />
				</Switch>
			</div>
		);
	}
}

export default App;


