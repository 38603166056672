import React from 'react';
import { Search, Close } from '@material-ui/icons';

import './style.css';

class SearchBar extends React.Component {
	render() {
		return (
			<div id="searchBar">
				<Search onClick={this.focus.bind(this)}/>
				<input ref="input" type="text" value={this.props.search} onChange={this.handleChange.bind(this)} placeholder="search"/>
				<Close onClick={this.setSearchQuery.bind(this, '')} className={this.props.search.length === 0 ? 'clearSearch' : 'clearSearch active'}/>
			</div>
		);
	}

	handleChange(e){
		this.setSearchQuery(e.target.value);
	}

	clear(){
		this.setSearchQuery('');
		this.focus();
	}

	focus(){
		this.refs['input'].focus();
	}

	setSearchQuery(val){
		this.props.onChange(val);
	}
}

export default SearchBar;