import React from 'react';
import ShutterButton from './ShutterButton';

import './style.css';
import Square from 'components/Square';

class ConnectorImageMaker extends React.Component {
	state = {
		view: 'choose',
		videoSrc: null,
		threshold: 10,
		keyColor: [100,100,100],
		viewportDimensions: {
			width: null,
			height: null
		}
	}

	componentDidMount(){
		this.props.onInstruction('Choose');
	}

	render() {
		return (
			<div id="connectorImageMaker">
				{
					this.state.view === 'choose' ?
						<div id="choose">
							<button disabled>upload image</button>
							<button onClick={this.changeView.bind(this, 'takePicture')}>take picture</button>
						</div>
						:
						null
				}

				{
					this.state.view === 'takePicture' ?
						<div id="takePicture">
							<Square id="liveView">
								<video muted  loop ref={video => this.liveView = video} autoPlay />
								<img src="/assets/connectorPreviews/usb.svg" alt="outline of usb connector"/>
							</Square>

							<div className="actions">
								<ShutterButton
									onClick={this.takePicture.bind(this)}
								/>
							</div>

							<canvas hidden ref={canvas => this.canvas = canvas}></canvas>
						</div>
						:
						null
				}
			</div>
		);
	}

	changeView(view){
		this.setState({view});
	}

	componentDidUpdate(oldProps, oldState){
		if(this.state.view === 'takePicture' && oldState.view !== 'takePicture'){
			this.startCamera();
		}
	}

	async startCamera(){
		this.ctx = this.canvas.getContext('2d');
		let constraints = { audio: false, video: { facingMode: 'environment' } };

		if(navigator.mediaDevices){
			let stream;
			try{
				stream = await navigator.mediaDevices.getUserMedia(constraints);
				this.liveView.srcObject = stream;
				this.props.onInstruction('Take a picture of the connector with a clear background');
			}
			catch(e){
				this.props.onInstruction('Error loading the camera');
				this.changeView('choose');
			}
		}
	}

	takePicture(){
		const width = this.liveView.videoWidth;
		const height = this.liveView.videoHeight;

		this.canvas.width = 500;
		this.canvas.height = 500;

		const smallSide = Math.min(width, height);

		const cropLeft = width/2 - smallSide/2;
		const cropTop = height/2 - smallSide/2;

		this.ctx.drawImage(this.liveView, cropLeft, cropTop, smallSide, smallSide, 0, 0, this.canvas.width, this.canvas.height);
		const imageData = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);

		this.props.onImageData(imageData);
	}

	
}

export default ConnectorImageMaker;