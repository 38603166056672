import React from 'react';
import SearchBar from 'components/SearchBar';
import CableList from 'components/CableList';
import { withRouter } from 'react-router-dom';
import StorageInterface from 'components/StorageInterface';

class Home extends React.Component {
	state = {
		search: '',
		cables: 'loading'
	}

	componentWillMount(){
		let cables = StorageInterface.getCables();
		this.setState({cables: cables});
	}

	render() {
		return (
			<div style={{width: '95%', margin: 'auto'}}>
				<h1>Cableorganizer</h1>
				
				<div style={{display: 'flex', justifyContent: 'center', margin: '20px 0px'}}>
					<SearchBar
						onChange={this.search.bind(this)}
						search={this.state.search}
					/>
					<button onClick={this.add.bind(this)} style={{marginLeft: '10px', flexShrink: 0}}>Add cable</button>
				</div>

				{
					this.state.cables === 'loading' ?
						<h2>Loading</h2>
						:
						<CableList
							search={this.state.search}
							cables={this.state.cables}
							onQuantityChange={this.handleQuantityChange.bind(this)}
							onDelete={this.handleDelete.bind(this)}
						/>
				}

				
			</div>
		);
	}

	search(val){
		this.setState({search: val});
	}

	handleQuantityChange(id, quantity){
		const cables = this.state.cables;
		cables[id].quantity = quantity;
		StorageInterface.storeCables(cables);
		this.setState({cables});
	}

	handleDelete(id){
		let cables = this.state.cables;
		cables.splice(id, 1);
		StorageInterface.storeCables(cables);
		this.setState({cables});
	}

	add(){
		this.props.history.push('/add');
	}
}

export default withRouter(Home);