import React from 'react';
import Cable from './Cable';

class CableList extends React.Component {
	componentDidMount(){

	}

	render() {
		let noDisplayedCables = true;
		return (
			<div style={style}>
				{
					this.props.cables.map((cableData, index) => {
						let search = this.props.search.toLowerCase();
						if(cableData.side1.toLowerCase().includes(search) || cableData.side2.toLowerCase().includes(search) || search === cableData.quantity.toString()){
							noDisplayedCables = false;
							return(
								<Cable
									key={index}
									id={index}
									search={this.props.search}
									quantity={cableData.quantity}
									side1={cableData.side1}
									side2={cableData.side2}
									onQuantityChange={this.props.onQuantityChange}
									onDelete={this.props.onDelete}
								/>
							);
						}
						else{
							return null;
						}
					})
				}

				{
					noDisplayedCables ? 
						<h2>Nothing found</h2>
						:
						null
				}
			</div>
		);
	}
}

export default CableList;

const style = {
	width: '100%',
	maxWidth: '600px',
	margin: 'auto'
};